<template>
  <div v-if="user" class="py-4 mx-4 md:mx-10">
    <router-link
      :to="{
        name: 'ContractorDetails',
        params: { contractorId: $route.params.contractorId },
      }"
      class="text-gray-dark text-sm inline-flex items-center mb-2"
    >
      <icon :icon="mdiChevronLeft" class="text-gray-dark" />
      {{ $t('common.back') }}
    </router-link>
    <h1 class="text-2xl">{{ user.firstName }} {{ user.lastName }}</h1>
    <div class="flex items-center mt-4 mb-2 text-sm">
      <span class="text-gray-dark mr-4 w-24">
        {{ $t('contractor-user-details-view.e-mail') }}:
      </span>
      <span class="font-semibold" v-html="email(user.email)"></span>
    </div>
    <div class="flex items-center mb-8 text-sm">
      <span class="text-gray-dark mr-4 w-24">
        {{ $t('contractor-user-details-view.subcontractor') }}:
      </span>
      <span
        class="border rounded-full px-6"
        :class="isSubcontractor ? 'text-green border-green' : 'text-gray-dark'"
      >
        {{ $t(`contractor-user-details-view.status-${isSubcontractor}`) }}
      </span>
    </div>
    <label class="font-semibold mt-8">
      {{ $t('contractor-user-details-view.brands') }}
    </label>
    <table-list
      content="brands"
      :cols="columns"
      :no-data-text-key="`contractor-user-details-view.no-brands`"
      sorting-enabled
      with-thumbs
      thumb-key="cdnLogoUrl"
      :thumb-cdn="IMAGE_THUMB_SIZE"
      :query="{
        contractorId,
        assignedToContractorUserId: userId,
      }"
    />
  </div>
</template>

<script>
import VueStore from '@/store';
import { SUBCONTRACTOR } from '@/store/modules/contractor';
import { email } from '@/util/formatters';
import { Icon } from '@/components/Icon';
import { mapState } from 'vuex';
import { IMAGE_THUMB_SIZE } from '@/util/images';
import TableList from '@/components/Table/TableList.vue';
import { mdiChevronLeft } from '@mdi/js';

export default {
  name: 'ContractorUserDetails',
  props: {
    contractorId: { type: String, required: true },
    userId: { type: String, required: true },
  },
  components: {
    Icon,
    TableList,
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('contractor/fetchContractorUser', {
      userId: to.params.userId,
    });
    next();
  },
  data() {
    return {
      mdiChevronLeft,
      columns: {
        name: {
          label: this.$t('contractor-user-details-view.name'),
        },
      },
      sortOrder: 'none',
      sortCol: null,
      IMAGE_THUMB_SIZE,
    };
  },
  computed: {
    ...mapState('contractor', {
      user: 'user',
    }),
    isSubcontractor() {
      return this.user.role === SUBCONTRACTOR;
    },
  },
  methods: {
    email,
    onSort({ col, type }) {
      this.sortOrder = type;
      this.sortCol = col;
    },
    sortEntries(value1, value2) {
      if (value1 === value2) return 0;

      if (
        (this.sortOrder === 'asc' && value1 < value2) ||
        (this.sortOrder === 'desc' && value1 > value2)
      )
        return 1;

      return -1;
    },
  },
};
</script>
